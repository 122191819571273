<template lang="pug">
include ../../../../configs/template.pug
div.row.text-left
  div.col-12
    +field-validation('body.number_page_book', 'numberPage', '["required"]')

  h5.text-bold-600.col-12.my-4 {{ $t('infoShip') }}:
  div.col-sm-12.col-md-4
    +field-validation('body.number_vessel', 'numShip', '["required"]')
  div.col-sm-12.col-md-4
    +field-validation('body.name_vessel', 'nameShip', '["required"]')
  div.col-sm-12.col-md-4
    +select-validation('body.type_vessel', 'shipTypesList', 'typeShip', 'nameLang', '["required"]')
  div.col-sm-12.col-md-6
    +select-validation('body.mode_of_navigation', 'shippingModesList', 'modeShipping', 'nameLang', '["required"]')
  div.col-sm-12.col-md-6
    +field-validation('body.port_of_registration', 'portShip', '["required"]')
  div.col-12
    +field('body.ship_owner', '"ownerShip"')

  h5.text-bold-600.col-12.my-4 {{ $t('ttx') }}:
  div.w-25
    +field-validation('body.gross_capacity', 'grossCapacity', '["required"]')(type="number")
  div.w-25
    +field-validation('body.propulsion_power', 'powerGEU', '["required"]')(type="number")
  div.w-25
    +field('body.refrigerating_power', '"coldProductivity"')(type="number")
  div.w-25
    +field('body.electrical_power', '"elEquipmentPower"')(type="number")
  div.col-sm-12.col-md-4
    +select-validation('body.type_geu', 'geuTypesList', 'typeGEU', 'nameLang', '["required"]')
  div.col-sm-12.col-md-4
    v-radio-group(v-model="body.level_refriger_plant" row).mt-0
      template(#label) {{ $t('levelRefrigerantPlant') }}
      v-radio(label="н/д" :value="null")
      v-radio(label="1" :value="1")
      v-radio(label="2" :value="2")
  div.col-sm-12.col-md-4
    v-radio-group(v-model="body.equipment_gmzlb" row).mt-0
      template(#label) {{ $t('apparatusGMZLB') }}
      v-radio(:label="$t('present')" :value="true")
      v-radio(:label="$t('missingFemale')" :value="false")

  h5.text-bold-600.col-12.my-4 {{ $t('port') }}:
  div.col-sm-12.col-md-6
    +field-validation('body.trading_area', 'swimArea', '["required"]')
  div.col-sm-12.col-md-6
    +field-validation('body.ports_input', 'swimPorts', '["required"]')

  h5.text-bold-600.col-12.my-4 {{ $t('captain') }}:
  div.col-12
    +field-validation('body.full_name_master', 'nameUK', '["alphaUA"]')
  h5.text-bold-600.col-12.my-4 {{ $t('captainEng') }}:
  div.col-12
    +field-validation('body.full_name_master_eng', 'nameEN', '["alphaEN", "required"]')

  h5.text-bold-600.col-12.my-4 {{ $t('employment') }}:
  div.col-12.mb-6
    div {{ $t('bookPractical') }}
    div.d-flex.align-start
      v-switch(v-model="body.book_registration_practical" hide-details).mr-2.mt-0
      div(v-if="body.book_registration_practical").mt-1 {{ $t('present') }}
      div(v-else).mt-1 {{ $t('missingFemale') }}
  div.col-sm-12.col-md-6
    +field-validation('body.place_start', 'hirePlace', '["required"]')
  div.col-sm-12.col-md-6
    +date-picker-validation('body.date_start', 'hireDate', 'dateStartObject', '["required", "minValue", "maxValue"]')(
      :max="body.date_end || new Date().toISOString()" min="1900-01-01")
  div.col-sm-12.col-md-6
    +field-validation('body.place_end', 'firePlace', '["required"]')
  div.col-sm-12.col-md-6
    +date-picker-validation('body.date_end', 'fireDate', 'dateEndObject', '["required", "minValue", "maxValue"]')(
      :max="new Date().toISOString()" :min="body.date_start || '1900-01-01'")
  div.col-12.mb-6
    div {{ $t('repairedShip') }}
    div.d-flex.align-start
      v-switch(v-model="body.is_repaired" hide-details).mr-2.mt-0
      div(v-if="body.is_repaired").mt-1 {{ $t('yes') }}
      div(v-else).mt-1 {{ $t('no') }}
  div(v-if="body.is_repaired").w-30
    v-menu(
      :close-on-content-click="false"
      max-width="290"
      transition="scale-transition"
      offset-y)
      template(#activator="{ on }")
        v-text-field(
          v-model="body.repair_date_from"
          @input="displayDateInputs('repairing')"
          @blur="$v.repairedDateFromObject.$touch()"
          :error-messages="$valid.validation($v.repairedDateFromObject, ['required', 'minValue', 'maxValue'])"
          :label="`${$t('periodStart')} *`"
          :disabled="readonlyInputs"
          type="date"
          max="9999-12-31"
        ).pt-0
          template(#append)
            v-btn(v-on="on" icon)
              v-icon mdi-calendar
      v-date-picker(
        v-model="body.repair_date_from"
        @input="displayDateInputs('repairing')"
        @blur="$v.repairedDateFromObject.$touch()"
        :max="body.repair_date_to || body.date_end"
        :min="body.date_start || '1900-01-01'"
        first-day-of-week="1"
        locale="uk"
        no-title
        scrollable)
  div(v-if="body.is_repaired").w-30
    v-menu(
      :close-on-content-click="false"
      max-width="290"
      transition="scale-transition"
      offset-y)
      template(#activator="{ on }")
        v-text-field(
          v-model="body.repair_date_to"
          @input="displayDateInputs('repairing')"
          @blur="$v.repairedDateToObject.$touch()"
          :error-messages="$valid.validation($v.repairedDateToObject, ['required', 'minValue', 'maxValue'])"
          :label="`${$t('periodEnd')} *`"
          :disabled="readonlyInputs"
          type="date"
          max="9999-12-31"
        ).pt-0
          template(#append)
            v-btn(v-on="on" icon)
              v-icon mdi-calendar
      v-date-picker(
        v-model="body.repair_date_to"
        @input="displayDateInputs('repairing')"
        @blur="$v.repairedDateToObject.$touch()"
        :max="body.date_end || new Date().toISOString()"
        :min="body.repair_date_from || body.date_start"
        first-day-of-week="1"
        locale="uk"
        no-title
        scrollable)
  div(v-if="body.is_repaired").w-10.d-flex.justify-center.align-center
    label {{ $t('or') }}
  div(v-if="body.is_repaired").w-30
    +field-validation('body.days_repair', 'totalDays', '["required"]')(
      @input="displayDateInputs('repairing')" :disabled="readonlyDateNum" type="number")
  div.col-sm-12.col-md-6.d-flex.align-center
    +select('responsibility', 'responsibilitiesList', 'responsibility', 'nameLang')(
      @input="addResponsibility(responsibility)").mr-2
    v-icon(@click="addResponsibility(responsibility)" large).cursor mdi-plus
  div.col-sm-12.col-md-6
    +select-validation('body.position', 'shipPositionsList', 'positionOnShip', 'nameLang', '["required"]')
  div(v-for="(responsibility, index) of body.all_responsibility" :key="responsibility.id").col-12
    div(v-if="responsibility.responsibility").mb-2
      label {{ responsibilityByID(responsibility.responsibility)[nameLang] }}:
      v-icon(@click="body.all_responsibility.splice(index, 1)").cursor.ml-2 mdi-close
      div.col-12.d-flex.flex-wrap.px-0.mt-2
        div.w-30.pl-0
          v-menu(
            :close-on-content-click="false"
            max-width="290"
            transition="scale-transition"
            offset-y)
            template(#activator="{ on }")
              v-text-field(
                v-model="body.all_responsibility[index].date_from"
                @input="displayDateInputs('responsibility')"
                @blur="$v.body.all_responsibility.$each[index].date_from.$touch()"
                :error-messages="$valid.validation($v.body.all_responsibility.$each[index].date_from, ['required'])"
                :label="`${$t('periodStart')} *`"
                :disabled="readonlyInputs"
                type="date"
                max="9999-12-31"
              ).pt-0
                template(#append)
                  v-btn(v-on="on" icon)
                    v-icon mdi-calendar
            v-date-picker(
              v-model="body.all_responsibility[index].date_from"
              @input="displayDateInputs('responsibility')"
              @blur="$v.body.all_responsibility.$each[index].date_from.$touch()"
              :max="body.date_end"
              :min="body.date_start"
              first-day-of-week="1"
              locale="uk"
              no-title
              scrollable)
        div.w-30
          v-menu(
            :close-on-content-click="false"
            max-width="290"
            transition="scale-transition"
            offset-y)
            template(#activator="{ on }")
              v-text-field(
                v-model="body.all_responsibility[index].date_to"
                @input="displayDateInputs('responsibility')"
                @blur="$v.body.all_responsibility.$each[index].date_to.$touch()"
                :error-messages="$valid.validation($v.body.all_responsibility.$each[index].date_to, ['required'])"
                :label="`${$t('periodEnd')} *`"
                :disabled="readonlyInputs"
                type="date"
                max="9999-12-31"
              ).pt-0
                template(#append)
                  v-btn(v-on="on" icon)
                    v-icon mdi-calendar
            v-date-picker(
              v-model="body.all_responsibility[index].date_to"
              @input="displayDateInputs('responsibility')"
              @blur="$v.body.all_responsibility.$each[index].date_to.$touch()"
              :max="body.date_end"
              :min="body.date_start"
              first-day-of-week="1"
              locale="uk"
              no-title
              scrollable)
        div.w-10.d-flex.justify-center.align-center
          label {{ $t('or') }}
        div.w-30.pr-0
          v-text-field(
            v-model="body.all_responsibility[index].days_work"
            @input="displayDateInputs('responsibility')"
            @blur="$v.body.all_responsibility.$each[index].days_work.$touch()"
            :error-messages="$valid.validation($v.body.all_responsibility.$each[index].days_work, ['required'])"
            :disabled="readonlyDateNum"
            :label="`${$t('totalDays')} *`"
            :placeholder="`${$t('totalDays')} *`"
            type="number").pt-1
  FileDropZone(ref="mediaContent" v-if="checkAccess('serviceRecordBookLine', 'add_file')").col-12
  div.col-12.d-flex.justify-center.mt-3
    v-btn(@click="saveServiceRecordBookLine" :loading="isLoading" color="success") {{ $t('save') }}
</template>

<script>
import FileDropZone from '@/components/atoms/DropZone/DropZone.vue'
import { clearBody } from '@/mixins/main'
import { mapState, mapGetters, mapActions } from 'vuex'
import { sailorServiceRecordBookLine } from '@/mixins/validationRules'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE } from '@/configs/constants'

const initBody = () => ({
  number_vessel: null,
  name_vessel: null,
  type_vessel: null,
  mode_of_navigation: null,
  port_of_registration: null,
  ship_owner: '',
  gross_capacity: null,
  propulsion_power: null,
  type_geu: null,
  trading_area: null,
  ports_input: null,
  full_name_master_eng: null,
  all_responsibility: [],
  position: null,
  date_start: null,
  date_end: null,
  place_start: null,
  place_end: null,
  number_page_book: null,
  is_repaired: false,
  repair_date_from: null,
  repair_date_to: null,
  days_repair: null,
  equipment_gmzlb: true,
  book_registration_practical: false,
  level_refriger_plant: null,
  full_name_master: '',
  electrical_power: null,
  refrigerating_power: null
})

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone
  },
  data () {
    return {
      body: initBody(),
      files: [],
      responsibility: null,
      isLoading: false,
      readonlyInputs: false,
      readonlyDateNum: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['responsibilityByID']),
    ...mapState({
      id: state => state.sailor.sailorId,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      shipTypesList: state => state.directory.typeShip,
      shippingModesList: state => state.directory.modeShipping,
      geuTypesList: state => state.directory.typeGEU,
      responsibilitiesList: state => state.directory.responsibility,
      shipPositionsList: state => state.directory.positionsShip
    }),
    serviceRecordID () { return this.$route.params.documentID },
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    },
    repairedDateFromObject () {
      return this.body.repair_date_from ? new Date(this.body.repair_date_from) : null
    },
    repairedDateToObject () {
      return this.body.repair_date_to ? new Date(this.body.repair_date_to) : null
    }
  },
  beforeMount () {
    if (this.$route.params?.lineID) {
      Object.keys(this.body).forEach(key => {
        this.body[key] = this.sailorDocument[key]
      })
      if (this.body.all_responsibility?.length) this.displayDateInputs('responsibility')
      if (this.body.is_repaired) this.displayDateInputs('repairing')
    }
  },
  validations () { return sailorServiceRecordBookLine(this) },
  methods: {
    ...mapActions(['updateRecordBookLineEntry', 'getRecordBookLineEntryByID', 'setRecordBookLineEntry']),

    /** Change readonly/disable property depends on which date format was entered first */
    displayDateInputs (element) {
      switch (element) {
        case 'responsibility':
          this.body.all_responsibility.forEach(record => {
            if (record.responsibility && !this.body.repair_date_to && !this.body.repair_date_from && !this.body.days_repair) {
              if ((record.date_to || record.date_from) && !record.days_work) {
                this.readonlyInputs = false
                this.readonlyDateNum = true
              } else if (record.days_work && (!record.date_from || !record.date_to)) {
                this.readonlyInputs = true
                this.readonlyDateNum = false
              } else if (!record.days_work && !record.date_from && !record.date_to) {
                this.readonlyInputs = false
                this.readonlyDateNum = false
              }
            }
          })
          break
        case 'repairing':
          if ((this.body.repair_date_to || this.body.repair_date_from) && !this.body.days_repair) {
            this.readonlyInputs = false
            this.readonlyDateNum = true
          } else if ((!this.body.repair_date_to || !this.body.repair_date_from) && this.body.days_repair) {
            this.readonlyInputs = true
            this.readonlyDateNum = false
          } else if (!this.body.repair_date_to && !this.body.repair_date_from && !this.body.days_repair) {
            this.readonlyInputs = false
            this.readonlyDateNum = false
          }
          break
      }
    },

    /** Adding and removing periods date */
    addResponsibility (resp) {
      console.log('resp :>> ', this.body)
      if (resp) {
        this.body.all_responsibility.push({
          responsibility: resp,
          days_work: null,
          date_from: null,
          date_to: null
        })
      }
    },

    /** Save edited line in record */
    async saveServiceRecordBookLine () {
      if (this.$v.$invalid) return this.$v.$touch()

      this.isLoading = true
      let data = {
        ...this.$route.params,
        body: {
          ...clearBody({ ...this.body }),
          all_responsibility: this.body.all_responsibility.filter(record => record.responsibility)
        },
        media: {
          files: [...this.$refs.mediaContent?.filesArray],
          file_type: 'experience_doc'
        }
      }
      const response = await this[`${!this.$route.params.lineID ? 'set' : 'update'}RecordBookLineEntry`](data)
      if (SUCCESS_CODE.includes(response?.code)) {
        if (!this.$route.params?.lineID) { // If called adding component
          this.$v.$reset()
          this.body = initBody()
          this.$parent.isViewAddSlot = false
        } else {
          if (this.$route.query?.viewEditBlock) this.$router.replace({ query: {} })
          this.$notification.success('editInfoRecordBook')
        }
      }
      this.isLoading = false
    }
  }
}
</script>
